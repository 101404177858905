import axios from "axios";

const ApiService = (path, method, query, body) => {
  const accessToken = localStorage.getItem("token");
  const url = `https://api.myfasto.com/v1${path}`;

  const options = {
    method,
    url,
    data: body ?? {},
    headers: {
      Authorization: "",
    },
  };

  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  return new Promise((resolve, reject) => {
    axios(options)
      .then(resolve)
      // eslint-disable-next-line consistent-return
      .catch(async (e) => {
        if (
          (e.response?.status === 401 && accessToken) ||
          e.response?.status === 403
        ) {
          localStorage.clear();
          window.location.href = "/";
        } else {
          return reject(e);
        }
      });
  });
};

export default ApiService;
