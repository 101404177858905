import React from "react";
import RouteLinks from "../Router/Routes";

const Nav = () => {
  const [path, setPath] = React.useState(RouteLinks.home);
  React.useEffect(() => {
    setPath(window.location.pathname);
  }, [path]);
  
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <a href={RouteLinks.home} className="navbar-brand ms-4 ms-lg-0">
        <h1 className="fw-bold text-primary m-0">
          <img src="assets/img/logo.png" alt="logo" width="100" />
        </h1>
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a
            href={RouteLinks.home}
            className={`nav-item nav-link ${
              path === RouteLinks.home ? "active" : ""
            }`}
          >
            Accueil
          </a>
          <a
            href={RouteLinks.help}
            className={`nav-item nav-link ${
              path === RouteLinks.help ? "active" : ""
            }`}
          >
            Qu'est ce que le crédit-bail ?
          </a>
          <a
            href={RouteLinks.faq}
            className={`nav-item nav-link ${
              path === RouteLinks.faq ? "active" : ""
            }`}
          >
            FAQ
          </a>

          <a
            href={RouteLinks.contact}
            className={`nav-item nav-link ${
              path === RouteLinks.contact ? "active" : ""
            }`}
          >
            Contact
          </a>
        </div>
        <div className="d-none d-lg-flex ms-2">
          <a
            className="btn btn-outline-primary py-2 px-3"
            href={RouteLinks.asker}
          >
            Demander un crédit
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
