import React from "react";
import { Spinner } from "react-bootstrap";

const ButtonLoader = () => {
  return (
    <button
      style={{ width: "max-content" }}
      className="btn btn-warning nextBtn btn-lg pull-right col-2 me-2"
      type="submit "
      disabled
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <span>&nbsp;</span>
      Chargement...
    </button>
  );
};

export default ButtonLoader;
