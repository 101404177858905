import React from "react";
import assetsLinks from "../Mock/links";
import RouteLinks from "../Router/Routes";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h1 className="fw-bold text-primary m-0">
                <img src="assets/img/logo.png" alt="logo" width="130" />
              </h1>
              <p className="mt-2">
                FASTO est votre courtier pour le financement de votre équipement
                en Cote d’Ivoire.
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
              <a className="btn btn-link" href={RouteLinks.home}>
                Accueil
              </a>
              <a className="btn btn-link" href={RouteLinks.help}>
                Crédit-bail ?
              </a>
              <a className="btn btn-link" href={RouteLinks.faq}>
                Nos Actualités
              </a>
              <a
                className="btn btn-link"
                href={assetsLinks.politique}
                target="_blank"
                rel="noreferrer"
              >
                Politique de confidentialité
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>Angré Djorobite
                Rond Point Cité SIR en bas Îlot 253 BIS Villa Lot 2446,
                Abidjan-Côte d’Ivoire
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>(+225) 07 08 06 48 48
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i>client@myfasto.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
