import React from "react";
import RouteLinks from "../Router/Routes";

const Home = () => {
  return (
    <>
      <div className="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src="assets/img/slide.jpg" alt="" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3 animated slideInDown home-title">
                        FASTO est votre courtier pour le financement de votre
                        équipement.
                      </h1>
                      <p className="fs-5 text-white-50 mb-5 animated slideInDown home-description">
                        Avec FASTO, vous n’effectuez votre demande qu'une seule
                        fois.
                        <br /> On se charge de la transmettre à toutes les
                        institutions de crédit-bail
                        <br /> en Côte d’Ivoire.
                      </p>
                      <a
                        className="btn btn-primary py-2 px-3 animated slideInDown"
                        href={RouteLinks.asker}
                      >
                        Demander un crédit
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                          <i className="fa fa-arrow-right"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6 mb-5" data-wow-delay="0.1s">
              <img
                src="assets/img/why.jpg"
                className="rounded mx-auto d-block"
                alt="..."
                width="100%"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                Nous sommes là pour vous !{" "}
              </div>
              <h1 className="display-6 mb-5">
                POURQUOI FAIRE APPEL À UN COURTIER EN CRÉDIT BAIL?
              </h1>
              <div className="mb-5">
                <ul>
                  <li>
                    {" "}
                    Vous êtes mieux équipés avec un courtier en crédit bail.
                  </li>
                  <li>
                    {" "}
                    Un accompagnement personnalisé pour vous trouver la
                    meilleure solution de financement.
                  </li>
                  <li>
                    {" "}
                    Une assistance sur-mesure pour booster votre éligibilité à
                    un crédit-bail si ce n’est pas le cas.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light my-5 py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6">
              <h1 className="display-6 mb-5">
                PROCÉDURES À SUIVRE POUR L'ACQUISITION D'ÉQUIPEMENT EN CRÉDIT
                BAIL
              </h1>

              <div className="mb-5">
                C’est facile et rapide. Voici les étapes pour obtenir votre
                financement:
                <br />
                <ul className="mb-5">
                  <li>Choisissez en premier lieu votre équipement</li>
                  <li>
                    Soumettez-nous votre liste d’équipements neufs ou usagés
                  </li>
                  <li>Remplissez notre demande de crédit en ligne</li>
                </ul>
              </div>
              <a
                className="btn btn-primary py-2 px-3 me-3 mb-2"
                href={RouteLinks.asker}
              >
                Demander un crédit
                <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                  <i className="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
            <div className="col-lg-6 col-md-6 mb-5" data-wow-delay="0.1s">
              <img
                src="assets/img/process.jpg"
                className="rounded mx-auto d-block"
                alt="..."
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
            <h1 className="display-6 mb-5">FASTO CÔTE D’IVOIRE</h1>
          </div>
          <div className="row g-4  justify-content-center">
            {/* <div
              className="col-lg-5 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="assets/team/team.jpeg "
                    alt=""
                    style={{ border: "5px solid #FE6F0F" }}
                  />
                </div>
              </div>
            </div> */}
            <div
              className="col-lg-5 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="assets/team/office.jpg"
                    alt=""
                    style={{ border: "5px solid #FE6F0F" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
