/* eslint-disable import/no-unresolved */
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Asker from "../Pages/Asker";
import Contact from "../Pages/Contact";
import Faq from "../Pages/Faq";
import Help from "../Pages/Help";
import Home from "../Pages/Home";
import NotFound from "../Pages/NotFound";
import RouteLinks from "./Routes";

const Router = createBrowserRouter([
  {
    path: RouteLinks.home,
    element: <Home />,
  },
  {
    path: RouteLinks.contact,
    element: <Contact />,
  },
  {
    path: RouteLinks.faq,
    element: <Faq />,
  },
  {
    path: RouteLinks.help,
    element: <Help />,
  },
  {
    path: RouteLinks.asker,
    element: <Asker />,
  },
  {
    path: RouteLinks.notFound,
    element: <NotFound />,
  },
]);

export default Router;
