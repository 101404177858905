import React from "react";

const Stepper = ({ stepper }) => {
  return (
    <div className="stepwizard">
      <div className="stepwizard-row setup-panel">
        <div className="stepwizard-step">
          {/* <p className="btn btn-circle {{ $currentStep != 1 ? 'btn-default' : 'btn-primary' }}"> */}
          <p
            className={`btn btn-circle ${
              stepper === 1 ? "btn-primary" : "btn-default"
            }`}
          >
            1
          </p>
          <p>Etape 1</p>
        </div>
        <div className="stepwizard-step">
          <p
            className={`btn btn-circle ${
              stepper === 2 ? "btn-primary" : "btn-default"
            }`}
          >
            2
          </p>
          <p>Etape 2</p>
        </div>
        <div className="stepwizard-step">
          <p
            className={`btn btn-circle ${
              stepper === 3 ? "btn-primary" : "btn-default"
            }`}
            disabled="disabled"
          >
            3
          </p>
          <p>Etape 3</p>
        </div>
        <div className="stepwizard-step">
          <p
            className={`btn btn-circle ${
              stepper === 4 ? "btn-primary" : "btn-default"
            }`}
            disabled="disabled"
          >
            4
          </p>
          <p>Etape 4</p>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
