import React from "react";
import Bacground from "../Components/Bacground";
import NewDemande from "../Components/NewDemande/NewDemande";

const Asker = () => {
  return (
    <>
      <Bacground />
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{
            maxWidth: "500px",
            visibility: "visible",
            animationDelay: "0.1s",
            animationName: "fadeInUp",
          }}
        >
          <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
            {" "}
            <a className=" py-2 px-3 me-3 mb-2" href="{{route('website.leasing')}}">
              Demander un crédit
              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                <i className="fa fa-arrow-right"></i>
              </div>
            </a>
          </div>
          <h1 className="display-6 mb-5">
            Veuillez svp remplir cet formulaire pour confirmer votre éligibilité
            à demander un crédit.
          </h1>
        </div>

        
        <NewDemande />
      </div>
    </>
  );
};

export default Asker;
