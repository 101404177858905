import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import Input from "../../Mock/Input";
import Form from "react-bootstrap/Form";
import { Col, FormGroup, Row } from "react-bootstrap";
import ButtonLoader from "../ButtonLoader";
import SessionService from "../../Services/SessionService";
import alertMock from "../../Mock/Alert";
import SetInCache from "../../Helpers/SetInCache";
import GetInCache from "../../Helpers/GetInCache";
import assetsLinks from "../../Mock/links";

const StepOne = ({ stepper, setStepper, setAlert }) => {
  const phoneRegExp =
    /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,7}$/im;
  const CacheValue = GetInCache()?.information;
  const [min, setMin] = React.useState(
    ![null, undefined].includes(CacheValue?.min_3_years_existance)
      ? CacheValue?.min_3_years_existance
      : "1"
  );
  const [loader, setLoader] = React.useState(false);
  const [politique, setPolitique] = React.useState(false);

  const initialValues = React.useCallback(() => {
    return {
      manager_name: CacheValue?.manager_name,
      legal_name: CacheValue?.legal_name,
      email: CacheValue?.email,
      phone: CacheValue?.phone,
      phone2: CacheValue?.phone2,
      core_business_area: CacheValue?.core_business_area,
      non_core_business_area: CacheValue?.non_core_business_area,
      location: CacheValue?.location,
      compagny_creation_date: CacheValue?.compagny_creation_date,
      min_3_years_existance: min,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CacheValue]);

  const YupValidation = yup.object().shape({
    manager_name: yup
      .string()
      .required(Input.required("Le nom de l'entreprise")),
    legal_name: yup.string().required(Input.required("Le nom et prenom")),
    email: yup
      .string()
      .email("Email incorrect")
      .required(Input.required("L'adresse email")),
    phone: yup
      .string()
      .matches(phoneRegExp, Input.phone)
      .required(Input.required("Le numero de telephone")),
    phone2: yup.string().matches(phoneRegExp, Input.phone),
    core_business_area: yup
      .string()
      .required(Input.required("Le secteur d'activite")),
    non_core_business_area: "",
    location: yup.string().required(Input.required("La localisation")),
    compagny_creation_date: yup
      .date(Input.required("Entrez la date"))
      .required("La date de Creation de l'entreprise"),
    min_3_years_existance: yup.string().required("Ce champ est requis"),
  });

  const handleSubmit = (values) => {
    if (!values.phone.includes("+225")) {
      values.phone = `+225${values.phone}`;
    } else if (![null, undefined, ""].includes(values.phone2)) {
      if (!values.phone2.includes("+225"))
        values.phone2 = `+225${values.phone2}`;
    }
    values.min_3_years_existance = min;
    setLoader(true);
    SessionService.CreateCompagny(values)
      .then((datas) => {
        const { status } = datas;
        const { data } = datas.data;
        const dataStatus = datas.data.error;
        setLoader(false);
        if ([404, 401, 500].includes(status) || dataStatus === true) {
          setAlert({
            status: "danger",
            content: alertMock.errorServeur,
          });
        } else {
          setAlert({
            status: "success",
            content: alertMock.successStepOne,
          });
          SetInCache(1, values);
          SetInCache(5, data.tenant);
          setStepper(2);
        }
      })
      .catch((error) => {
        setLoader(false);
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            setAlert({
              status: "danger",
              content: error?.response?.data.message,
            });
          }
        } else {
          setAlert({
            state: "danger",
            content: alertMock.errorServeur,
          });
        }
      });
  };
  return (
    <div>
      <Formik
        validationSchema={YupValidation}
        onSubmit={handleSubmit}
        initialValues={initialValues()}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3" style={{ gap: "20px 0" }}>
                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFormik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Nom légal de l’entreprise
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="legal_name"
                    value={values.legal_name}
                    onChange={handleChange}
                    isValid={touched.legal_name && !errors.legal_name}
                    isInvalid={touched.legal_name && errors.legal_name}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.legal_name}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFormik012">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Nom et prénoms
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="manager_name"
                    value={values.manager_name}
                    onChange={handleChange}
                    isValid={touched.manager_name && !errors.manager_name}
                    isInvalid={touched.manager_name && errors.manager_name}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.manager_name}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFormADSik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Email
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && errors.email}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validatiADSonFormik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Localisation
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    isValid={touched.location && !errors.location}
                    isInvalid={touched.location && errors.location}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.location}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFoHSRFrmik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Numero de telephone
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    isValid={touched.phone && !errors.phone}
                    isInvalid={touched.phone && errors.phone}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFdfaormik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Numero de telephone secondaire
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone2"
                    value={values.phone2}
                    onChange={handleChange}
                    isValid={touched.phone2 && !errors.phone2}
                    isInvalid={touched.phone2 && errors.phone2}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.phone2}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFormiadsfk01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Date officielle de création de l’entreprise
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="compagny_creation_date"
                    value={values.compagny_creation_date}
                    onChange={handleChange}
                    isValid={
                      touched.compagny_creation_date &&
                      !errors.compagny_creation_date
                    }
                    isInvalid={
                      touched.compagny_creation_date &&
                      errors.compagny_creation_date
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.compagny_creation_date}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validadsfationFormik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Votre entreprise a-t-elle un minimum de 3 années d'existence
                    ?<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name=" min_3_years_existance"
                    label="oui"
                    onChange={(event) => setMin("1")}
                    id="validationFormik106"
                    value={min}
                    defaultChecked={min === "1" ? true : false}
                    type="radio"
                  />
                  <Form.Check
                    type="radio"
                    required
                    name=" min_3_years_existance"
                    defaultChecked={min === "0" ? true : false}
                    label="non"
                    onChange={(event) => setMin("0")}
                    id="validationFormik107"
                    value={min}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.min_3_years_existance}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validatioadsfnFormik01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Secteur d’activités principale
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="core_business_area"
                    value={values.core_business_area}
                    onChange={handleChange}
                    isValid={
                      touched.core_business_area && !errors.core_business_area
                    }
                    isInvalid={
                      touched.core_business_area && errors.core_business_area
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.core_business_area}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}

                {/* -------------------------------------- */}

                <Form.Group as={Col} md="4" controlId="validationFormiadsk01">
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Secteur d’activités secondaire
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="non_core_business_area"
                    value={values.non_core_business_area}
                    onChange={handleChange}
                    isValid={
                      touched.non_core_business_area &&
                      !errors.non_core_business_area
                    }
                    isInvalid={
                      touched.non_core_business_area &&
                      errors.non_core_business_area
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.non_core_business_area}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* -------------------------------------- */}
              </Row>

              <Row>
                <FormGroup as={Row}>
                  <Form.Check
                    type="checkbox"
                    name="politique"
                    id='politique'
                    value={politique}
                    onChange={() => setPolitique((state) => !state)}
                    label={
                      <p htmlFor={'politique'}>
                        Acceptez notre{" "}
                        <a
                          style={{ display: "inline" }}
                          href={assetsLinks.politique}
                        >
                          politique de confidentialité
                        </a>{" "}
                        pour continuer
                      </p>
                    }
                  />
                </FormGroup>
              </Row>
              <div className="row g-4 justify-content-start">
                <button
                  style={{ width: "max-content" }}
                  className={`btn btn-danger nextBtn btn-lg pull-right col-2 me-2  ${
                    stepper === 1 ? "disabled" : ""
                  }`}
                  type="button"
                >
                  Précédent
                </button>
                {!loader && (
                  <button
                    style={{ width: "max-content" }}
                    className="btn btn-primary nextBtn btn-lg pull-right col-2 me-2"
                    type="submit "
                    disabled={!politique}
                  >
                    Suivant
                  </button>
                )}
                {loader && <ButtonLoader />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default StepOne;
