import React from "react";
import GetInCache from "../../Helpers/GetInCache";
import SetInCache from "../../Helpers/SetInCache";

const StepTwo = ({ stepper, setStepper, handleDocumentList }) => {
  const CacheValue = GetInCache()?.document;
  const defaultValue = [
    {
      property: "managerID",
      title: "Photocopie de la pièce du gérant ou du chef d’entreprise (scan)",
      status: false,
    },
    {
      property: "compagny_statutes",
      title: "Copie des statuts de la société",
      status: false,
    },
    {
      property: "commercial_register",
      title:
        "Registre de commerce authentifié par le tribunal du commerce (scan)",
      status: false,
    },
    {
      property: "tax_declaration",
      title: "Déclaration d'existence fiscale DFE (scan)",
      status: false,
    },
    {
      property: "g_map",
      title: "Plan de localisation Google Maps",
      status: false,
    },
    {
      property: "receipt",
      title: "Copie d’une quittance CIE/SODECI (scan)",
      status: false,
    },
    {
      property: "rib_iban",
      title: "Relevé d’identité bancaire (RIB ou IBAN)",
      status: false,
    },
    {
      property: "bank_statements",
      title:
        "Relevés bancaires des 6 derniers mois (totaux mouvements et soldes/mois)",
      status: false,
    },
    {
      property: "financial_statement",
      title:
        "États financiers visés par un cabinet comptable des 3 derniers exercices (liasse complète syscohada)",
      status: false,
    },
    {
      property: "general_balance",
      title: "Situation à mi-parcours ou Balance générale des comptes",
      status: false,
    },
    {
      property: "business_plan",
      title: "Prévisionnel (business plan)",
      status: false,
    },
    {
      property: "in_execution_contracts",
      title: "États des marchés en cours d’exécution",
      status: false,
    },
    {
      property: "machinery",
      title: "Etat du parc auto et du parc machines en exploitation",
      status: false,
    },
  ];
  const [documentList, setDocumentList] = React.useState(
    CacheValue ? CacheValue : defaultValue
  );
  const [next, setNext] = React.useState(CacheValue ? false : true);

  const handleChange = React.useCallback(
    (event, item) => {
      const value = event.target.checked;
      const index = documentList.findIndex(
        (currentItem) => currentItem.title === item.title
      );
      const newDocumentList = [...documentList];
      newDocumentList[index].status = value;
      setDocumentList(newDocumentList);
      const isChange = documentList.findIndex(
        (currentItem) => currentItem.status === true
      );
      if (isChange >= 0) {
        setNext(false);
      } else {
        setNext(true);
      }
    },
    [documentList]
  );

  const handleSubmit = React.useCallback(() => {
    if (next === false) {
      const newDocumentList = {};
      documentList.forEach((item) => {
        newDocumentList[item.property] = item.status;
      });
      handleDocumentList(newDocumentList);
      SetInCache(2, documentList);
      setStepper(3);
    }
  }, [documentList, handleDocumentList, next, setStepper]);
  return (
    <div
      className="row setup-content {{ $currentStep != 2 ? 'displayNone' : '' }}"
      id="step-2"
    >
      <div className="row g-4 justify-content-start">
        <div className="d-inline-block rounded-pill bg-default text-primary py-1 px-3 mb-3">
          Vos documents
        </div>
        <label className="form-label">
          Pouvez-vous fournir les documents suivants plus tard sur demande
          (cocher oui si vous les avez)
          <span style={{ color: "red" }}>*</span>{" "}
        </label>

        {documentList.map((item, key) => (
          <div className="form-check" key={key}>
            <input
              className="form-check-input"
              type="checkbox"
              id={item.title}
              onChange={(event) => handleChange(event, item)}
              checked={item.status}
            />
            <label className="form-check-label" htmlFor={item.title}>
              {item.title}
            </label>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "40px", marginBottom: "10px" }}>
        {next === true && (
          <span style={{ color: "red" }}>
            Veuillez sélectionner au moins un fichier
          </span>
        )}
      </div>
      <div className="row  justify-content-start">
        <button
          style={{ width: "max-content" }}
          className="btn btn-danger nextBtn btn-lg pull-right col-2 me-2"
          type="button"
          onClick={() => setStepper(1)}
        >
          Précédent
        </button>
        <button
          style={{ width: "max-content" }}
          className="btn btn-primary nextBtn btn-lg pull-right col-2 me-2"
          type="button"
          disabled={next}
          onClick={handleSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
