const RouteLinks = {
  home: "/",
  contact: "/contact",
  faq: "/faq",
  help: "/credit-bail",
  asker: "/demande-de-credit",
  notFound: "*",
};

export default RouteLinks;
