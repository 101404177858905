import ApiService from "./ApiService";
import ServiceRoutes from "./ServiceRoutes";

const SessionService = {
  async CreateCompagny(values) {
    return ApiService(ServiceRoutes.CreateCompagny, "post", "", values);
  },
  async SendFile(uuid, values) {
    const body = {
      leasing_request_id: uuid,
      financial_statements: values,
    };

    return ApiService(ServiceRoutes.SendFile, "post", "", body);
  },
  async CreatRequest(values) {
    const body = {
      ...values,
    };
    return ApiService(ServiceRoutes.TestDemande, "post", "", body);
  },
  async CreateAssitance(uuid) {
    const body = {
      lease_uuid: uuid,
    };

    return ApiService(ServiceRoutes.CreateAssistance, "post", "", body);
  },
};

export default SessionService;
