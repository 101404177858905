import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import Input from "../../Mock/Input";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import ButtonLoader from "../ButtonLoader";
import SessionService from "../../Services/SessionService";
import alertMock from "../../Mock/Alert";
import SetInCache from "../../Helpers/SetInCache";
import GetInCache from "../../Helpers/GetInCache";
import uuidv4 from "../../Helpers/uuidv4";

const StepThree = ({ stepper, setStepper, documentInformation, setAlert }) => {
  const CacheValue = GetInCache()?.test;
  const [loader, setLoader] = React.useState(false);

  const initialValues = {
    rolling_stock: CacheValue?.rolling_stock,
    new_equipment: CacheValue?.new_equipment,
    local_provider: CacheValue?.local_provider,
    legal_provider: CacheValue?.legal_provider,
    equipement_description: CacheValue?.equipement_description,
    equipement_web_link: CacheValue?.equipement_web_link,
    funding_period: CacheValue?.funding_period,
    funding_amount: CacheValue?.funding_amount,
    capital: CacheValue?.capital,
    last_contract_balance: CacheValue?.last_contract_balance,
    title: CacheValue?.title,
    outstanding_loans:
      CacheValue?.outstanding_loans !== undefined
        ? CacheValue?.outstanding_loans === true
          ? "1"
          : "0"
        : "",
    outstanding_loans_amount: CacheValue?.outstanding_loans_amount,
    default_to_pay:
      CacheValue?.default_to_pay !== undefined
        ? CacheValue?.default_to_pay === true
          ? "1"
          : "0"
        : "",
  };

  const validationSchema = yup.object().shape({
    rolling_stock: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    new_equipment: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    local_provider: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    legal_provider: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    equipement_description: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    equipement_web_link: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .url("Entrez une URL valide"),
    funding_period: yup
      .number("ce champ doit être un nombre entier positif.")
      .required("Ce champ est requis")
      .positive("ce champ doit être un nombre entier positif.")
      .integer("ce champ doit être un nombre entier positif.")
      .max(1, "ce champ doit être inférieur ou égal à 36.")
      .max(36, "ce champ doit être supérieur ou égal à 1."),
    funding_amount: yup
      .number()
      .required("Ce champ est requis")
      .positive("ce champ doit être un nombre entier positif."),
    capital: yup
      .number("ce champ doit être un nombre entier positif.")
      .required("Ce champ est requis")
      .positive("ce champ doit être un nombre entier positif."),
    last_contract_balance: yup
      .number("ce champ doit être un nombre entier positif.")
      .required("Ce champ est requis")
      .positive("ce champ doit être un nombre entier positif."),
    title: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    outstanding_loans: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
    outstanding_loans_amount: yup.number().when("outstanding_loans", {
      is: "1",
      then: (schema) => schema.required("Ce champ est requis").positive(),
    }),
    default_to_pay: yup
      .string("Ce champ doit être une chaîne de caractères.")
      .required("Ce champ est requis"),
  });

  const handleSubmit = React.useCallback(
    (equipementInfo) => {
      equipementInfo.outstanding_loans =
        equipementInfo.outstanding_loans === "1";
      equipementInfo.default_to_pay = equipementInfo.default_to_pay === "1";
      equipementInfo.outstanding_loans_amount =
        equipementInfo.outstanding_loans === true
          ? equipementInfo.outstanding_loans_amount
          : "";

      const uuid = ![undefined, null].includes(GetInCache()?.uuid)
        ? GetInCache()?.uuid
        : uuidv4();

      SetInCache(3, equipementInfo);
      SetInCache(6, uuid);

      const compagnyInformation = GetInCache()?.information;
      const tenant = GetInCache()?.tenant;

      if (documentInformation !== null) {
        setLoader(true);
        const FormData = {
          lease_uuid: uuid,
          tenant_id: parseInt(tenant),
          ...documentInformation,
          compagny_creation_date: compagnyInformation.compagny_creation_date,
          min_3_years_existance: compagnyInformation.min_3_years_existance,
          ...equipementInfo,
        };

        SessionService.CreatRequest(FormData)
          .then((values) => {
            const { status } = values;
            const { data } = values.data;
            const dataStatus = values.data.error;
            setLoader(false);
            if ([404, 401, 500].includes(status) || dataStatus === true) {
              setAlert({
                status: "error",
                content: alertMock.errorServeur,
              });
            } else {
              SetInCache(7, data.leasing_request_id);
              setAlert({
                status:
                  data.status === "green"
                    ? "success"
                    : data.status === "orange"
                    ? "warning"
                    : "danger",
                content: data.message,
              });

              if (["green", "orange"].includes(data.status)) {
                setStepper(4);
              }
            }
          })
          .catch((error) => {
            setLoader(false);
            if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
              if (error.response.data.error === true) {
                setAlert({
                  status: "danger",
                  content: error?.response?.data.message,
                });
              }
            } else {
              setAlert({
                state: "danger",
                content: alertMock.errorServeur,
              });
            }
          });
      }
    },
    [documentInformation, setAlert, setStepper]
  );

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => {
          console.log(errors, values);
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Row
                className="mb-3"
                style={{ gap: "25px 0", alignItems: "flex-start" }}
              >
                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFormik01a"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Équipement(s) souhaite(s)
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    isValid={touched.title && !errors.title}
                    isInvalid={touched.title && errors.title}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFormik01"
                  onChange={handleChange}
                  value={values.rolling_stock}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    S’agit-il de matériel(s) roulant(s) ?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="rolling_stock"
                    label="Oui"
                    id="validationFormik1s06"
                    value={"1"}
                    type="radio"
                    isInvalid={touched.rolling_stock && errors.rolling_stock}
                    isValid={touched.rolling_stock && !errors.rolling_stock}
                    defaultChecked={values.rolling_stock === "1" ? true : false}
                  />
                  <Form.Check
                    required
                    name="rolling_stock"
                    label="Non"
                    id="validationFormik10d6"
                    value={"0"}
                    defaultChecked={values.rolling_stock === "0" ? true : false}
                    isInvalid={touched.rolling_stock && errors.rolling_stock}
                    isValid={touched.rolling_stock && !errors.rolling_stock}
                    type="radio"
                  />
                  {touched.rolling_stock && Boolean(errors.rolling_stock) ? (
                    <p className="text-danger">{errors.rolling_stock}</p>
                  ) : touched.rolling_stock && !errors.rolling_stock ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFormikf01"
                  onChange={handleChange}
                  value={values.new_equipment}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    S’agit-il de matériel neuf
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="new_equipment"
                    label="Oui"
                    id="validationFormik10as6"
                    value={"1"}
                    type="radio"
                    isInvalid={touched.new_equipment && errors.new_equipment}
                    isValid={touched.new_equipment && !errors.new_equipment}
                    defaultChecked={values.new_equipment === "1"}
                  />
                  <Form.Check
                    required
                    name="new_equipment"
                    label="Non"
                    id="validationFormik10fd6"
                    value={"0"}
                    isInvalid={touched.new_equipment && errors.new_equipment}
                    isValid={touched.new_equipment && !errors.new_equipment}
                    defaultChecked={values.new_equipment === "0"}
                    type="radio"
                  />
                  {touched.new_equipment && Boolean(errors.new_equipment) ? (
                    <p className="text-danger">{errors.new_equipment}</p>
                  ) : touched.new_equipment && !errors.new_equipment ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFoarmik01"
                  onChange={handleChange}
                  value={values.local_provider}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Le fournisseur est-il local (présence en Cote d’Ivoire) ?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="local_provider"
                    label="Oui"
                    id="validationFordmik1f06"
                    value={"oui"}
                    isInvalid={touched.local_provider && errors.local_provider}
                    isValid={touched.local_provider && !errors.local_provider}
                    defaultChecked={values.local_provider === "oui"}
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="local_provider"
                    label="Non"
                    id="validationFoasdrmik1a06"
                    value={"non"}
                    defaultChecked={values.local_provider === "non"}
                    isInvalid={touched.local_provider && errors.local_provider}
                    isValid={touched.local_provider && !errors.local_provider}
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="local_provider"
                    label="Je ne sais pas"
                    id="validationForadsfmik10s6"
                    value={"Je ne sais pas"}
                    isInvalid={touched.local_provider && errors.local_provider}
                    isValid={touched.local_provider && !errors.local_provider}
                    type="radio"
                    defaultChecked={values.local_provider === "Je ne sais pas"}
                  />
                  {touched.local_provider && Boolean(errors.local_provider) ? (
                    <p className="text-danger">{errors.local_provider}</p>
                  ) : touched.local_provider && !errors.local_provider ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="valiasdfdationFormik01"
                  onChange={handleChange}
                  value={values.legal_provider}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Le fournisseur est-il un professionnel représentant
                    légalement la marque
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="legal_provider"
                    label="Oui"
                    id="validationasdfFormik1f06"
                    value={"oui"}
                    defaultChecked={values.legal_provider === "oui"}
                    isInvalid={touched.legal_provider && errors.legal_provider}
                    isValid={touched.legal_provider && !errors.legal_provider}
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="legal_provider"
                    label="Non"
                    id="validationasdfFormik1a06"
                    value={"non"}
                    defaultChecked={values.legal_provider === "non"}
                    isInvalid={touched.legal_provider && errors.legal_provider}
                    isValid={touched.legal_provider && !errors.legal_provider}
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="legal_provider"
                    label="Je ne sais pas"
                    id="validationFormik10s6"
                    value={"Je ne sais pas"}
                    defaultChecked={values.legal_provider === "je ne sais pas"}
                    isInvalid={touched.legal_provider && errors.legal_provider}
                    isValid={touched.legal_provider && !errors.legal_provider}
                    type="radio"
                  />
                  {touched.legal_provider && Boolean(errors.legal_provider) ? (
                    <p className="text-danger">{errors.legal_provider}</p>
                  ) : touched.legal_provider && !errors.legal_provider ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFasdfasdormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Description de la nature de(s) équipement(s)
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as={"textarea"}
                    rows={5}
                    name="equipement_description"
                    value={values.equipement_description}
                    onChange={handleChange}
                    isValid={
                      touched.equipement_description &&
                      !errors.equipement_description
                    }
                    isInvalid={
                      touched.equipement_description &&
                      errors.equipement_description
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.equipement_description}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validatioasdfadsfaanFormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Un lien web de(s) équipement(s) (optionnel)
                  </Form.Label>
                  <Form.Control
                    type="link"
                    name="equipement_web_link"
                    value={values.equipement_web_link}
                    onChange={handleChange}
                    isValid={
                      touched.equipement_web_link && !errors.equipement_web_link
                    }
                    isInvalid={
                      touched.equipement_web_link && errors.equipement_web_link
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.equipement_web_link}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validatiasdfffasdonFormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Quelle est la durée de financement souhaitée (36 mois ou
                    moins est la durée généralement admise)
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="funding_period"
                    value={values.funding_period}
                    onChange={handleChange}
                    isValid={touched.funding_period && !errors.funding_period}
                    isInvalid={touched.funding_period && errors.funding_period}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.funding_period}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validation3434aaFormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Quel est le montant du financement sollicité
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="funding_amount"
                    value={values.funding_amount}
                    onChange={handleChange}
                    isValid={touched.funding_amount && !errors.funding_amount}
                    isInvalid={touched.funding_amount && errors.funding_amount}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.funding_amount}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validation422dfggFormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Quel est le montant de vos capitaux propres/fonds propres ?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="capital"
                    value={values.capital}
                    onChange={handleChange}
                    isValid={touched.capital && !errors.capital}
                    isInvalid={touched.capital && errors.capital}
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.capital}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validati111334faaonFormik01"
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Quel est votre total bilan de l’exercice dernier ?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="last_contract_balance"
                    value={values.last_contract_balance}
                    onChange={handleChange}
                    isValid={
                      touched.last_contract_balance &&
                      !errors.last_contract_balance
                    }
                    isInvalid={
                      touched.last_contract_balance &&
                      errors.last_contract_balance
                    }
                  />
                  <Form.Control.Feedback>{Input.correct}</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.last_contract_balance}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationF666hyhhhormik01"
                  onChange={handleChange}
                  value={values.outstanding_loans}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Avez vous déjà des encours de crédits avec des banques et
                    institutions financières ?
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="outstanding_loans"
                    label="Oui"
                    id="validationFormik106"
                    value={"1"}
                    defaultChecked={values.outstanding_loans === "1"}
                    isInvalid={
                      touched.outstanding_loans && errors.outstanding_loans
                    }
                    isValid={
                      touched.outstanding_loans && !errors.outstanding_loans
                    }
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="outstanding_loans"
                    label="Non"
                    id="validationFormik106"
                    value={"0"}
                    defaultChecked={values.outstanding_loans === "0"}
                    isInvalid={
                      touched.outstanding_loans && errors.outstanding_loans
                    }
                    isValid={
                      touched.outstanding_loans && !errors.outstanding_loans
                    }
                    type="radio"
                  />
                  {touched.outstanding_loans &&
                  Boolean(errors.outstanding_loans) ? (
                    <p className="text-danger">{errors.outstanding_loans}</p>
                  ) : touched.outstanding_loans && !errors.outstanding_loans ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                {values.outstanding_loans === "1" && (
                  <Form.Group
                    as={Col}
                    md="6"
                    n
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      height: "130px",
                    }}
                    controlId="validationFasd111hhhormik01"
                  >
                    <Form.Label style={{ fontSize: "1.2rem" }}>
                      Si oui, quel est le montant ?
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="outstanding_loans_amount"
                      value={values.outstanding_loans_amount}
                      onChange={handleChange}
                      isValid={
                        touched.outstanding_loans_amount &&
                        !errors.outstanding_loans_amount
                      }
                      isInvalid={
                        touched.outstanding_loans_amount &&
                        errors.outstanding_loans_amount
                      }
                    />
                    <Form.Control.Feedback>
                      {Input.correct}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.outstanding_loans_amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                {/* --------------------------------------------- */}

                {/* --------------------------------------------- */}

                <Form.Group
                  as={Col}
                  md="6"
                  n
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "130px",
                  }}
                  controlId="validationFormik01"
                  onChange={handleChange}
                  value={values.default_to_pay}
                >
                  <Form.Label style={{ fontSize: "1.2rem" }}>
                    Avez vous fait l’objet d'une cessation de paiement ou d'un
                    règlement préventif ?<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Check
                    required
                    name="default_to_pay"
                    label="Oui"
                    id="validationFormik106"
                    value={"1"}
                    defaultChecked={values.default_to_pay === "1"}
                    isInvalid={touched.default_to_pay && errors.default_to_pay}
                    isValid={touched.default_to_pay && !errors.default_to_pay}
                    type="radio"
                  />
                  <Form.Check
                    required
                    name="default_to_pay"
                    label="Non"
                    id="validationFormik106"
                    value={"0"}
                    defaultChecked={values.default_to_pay === "0"}
                    isInvalid={touched.default_to_pay && errors.default_to_pay}
                    isValid={touched.default_to_pay && !errors.default_to_pay}
                    type="radio"
                  />
                  {touched.default_to_pay && Boolean(errors.default_to_pay) ? (
                    <p className="text-danger">{errors.default_to_pay}</p>
                  ) : touched.default_to_pay && !errors.default_to_pay ? (
                    <p className="text-success">{Input.correct}</p>
                  ) : null}
                </Form.Group>

                {/* --------------------------------------------- */}
              </Row>

              <div className="row  justify-content-start">
                <button
                  style={{ width: "max-content" }}
                  className="btn btn-danger nextBtn btn-lg pull-right col-2 me-2"
                  type="button"
                  onClick={() => setStepper(2)}
                >
                  Précédent
                </button>

                {!loader && (
                  <button
                    style={{ width: "max-content" }}
                    className="btn btn-primary nextBtn btn-lg pull-right col-2 me-2"
                    type="submit "
                  >
                    Suivant
                  </button>
                )}
                {loader && <ButtonLoader />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default StepThree;
