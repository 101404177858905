import React from "react";
import Bacground from "../Components/Bacground";
import RouteLinks from "../Router/Routes";

const Help = () => {
  return (
    <>
      <Bacground />

      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div
              className="col-lg-8 wow fadeIn"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeIn",
              }}
            >
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                Crédit-bail d'équipement, en comprendre l'essentiel !
              </div>
              <h1 className="display-6 mb-5">C’est quoi le crédit- bail</h1>
              <p className="mb-0">
                Le crédit-bail est une forme de financement qui permet aux
                entreprises d'acquérir des biens sans avoir à les payer
                immédiatement. Les entreprises peuvent ainsi bénéficier des
                avantages du crédit-bail, tels que la flexibilité des paiements,
                sans avoir à supporter les coûts élevés du crédit.
              </p>
              <br />
              <p className="mb-0">
                Le crédit-bail est une solution de financement reconnue qui
                permet à une entreprise d’acquérir simplement et rapidement de
                l’équipement et de l’utiliser pendant une période donnée. Il ne
                s’agit pas d’un achat, mais d’une location avec ou sans option
                d’achat à la fin du terme.
              </p>

              <br />
              <br />
              <h1 className="display-6 mb-5">Les avantages du crédit-bail</h1>
              <p className="mb-0">
                Lorsqu’il est question d’acquisition d’équipement, le
                crédit-bail est une solution de financement qui propose des
                avantages à plusieurs niveaux. Jetez un œil.
              </p>
              <h5 className="mb-2 mt-5">
                # Solution simple, rapide et concurrentielle
              </h5>
              <div className="mb-0">
                <ul>
                  <li>Accès au financement via une demande unique</li>
                  <li>Prise en charge rapide.</li>
                  <li>
                    Conditions de financement concurrentielles et en fonction du
                    crédit de l’entreprise.
                  </li>
                </ul>
              </div>

              <h5 className="mb-2 mt-5"># Préservation de votre capital</h5>
              <div className="mb-0">
                <ul>
                  <li>
                    Structure de financement flexible sans mise de fonds
                    importante.
                  </li>
                  <li>Protection du fonds de roulement de l’entreprise.</li>
                  <li>Avantages fiscaux.</li>
                </ul>
              </div>

              <h5 className="mb-2 mt-5"># Protection contre la désuétude</h5>
              <div className="mb-0">
                <ul>
                  <li>
                    Renouvellement possible de l’équipement en fin de terme.
                  </li>
                  <li>
                    Protection contre l’inflation, les loyers étant fixes pour
                    la durée du crédit-bail.
                  </li>
                  <li>
                    Coût mensuel de l’équipement arrimé avec les revenus de
                    votre entreprise.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <strong>
                  <a
                    className="btn btn-primary py-2 px-3  slideInDown"
                    href={RouteLinks.asker}
                  >
                    Demander un crédit
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                  </a>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
