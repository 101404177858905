const SetInCache = (state, content) => {
  if (localStorage.getItem("demande")) {
    const demande = JSON.parse(localStorage.getItem("demande"));
    switch (state) {
      case 1:
        demande.information = content;
        break;

      case 2:
        demande.document = content;
        break;

      case 3:
        demande.test = content;
        break;
      case 4:
        demande.file = content;
        break;
      case 5:
        demande.tenant = content;
        break;

      case 6:
        demande.uuid = content;
        break;

      case 7:
        demande.leaseId = content;
        break;

      default:
        break;
    }
    localStorage.setItem("demande", JSON.stringify(demande));
  } else {
    const demande = {
      information: content,
      document: null,
      test: null,
      file: null,
      tenant: null,
      uuid: null,
      leaseId: null,
    };
    localStorage.setItem("demande", JSON.stringify(demande));
  }
};

export default SetInCache;
