import React from "react";
import { Form } from "react-bootstrap";
import ConvertFileInBase64 from "../../Helpers/ConvertFileInBase64";
import GetInCache from "../../Helpers/GetInCache";
import alertMock from "../../Mock/Alert";
import SessionService from "../../Services/SessionService";
import ButtonLoader from "../ButtonLoader";

const StepFour = ({ setAlert, setStepper }) => {
  const [select, setSelect] = React.useState("true");
  const [baseImage, setBaseImage] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const uploadImg = React.useCallback(
    async (e) => {
      const newBaseImage = [];
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];

        const base64 = await ConvertFileInBase64(file);
        const { name } = file;
        const newState = { content: "", title: "" };
        newState.content = base64;
        newState.title = name;
        newBaseImage.push(newState);
      }
      setBaseImage([...newBaseImage, ...baseImage]);
    },
    [baseImage]
  );

  const handleDelete = React.useCallback(
    (index) => {
      const newState = [...baseImage];
      newState.splice(index, 1);
      setBaseImage(newState);
    },
    [baseImage]
  );

  const handleSubmit = () => {
    setLoader(true);
    SessionService.SendFile(GetInCache()?.leaseId, baseImage)
      .then((datas) => {
        const { status } = datas;
        const { data } = datas.data;
        const dataStatus = datas.data.error;
        setLoader(false);
        if ([404, 401, 500].includes(status) || dataStatus === true) {
          setAlert({
            status: "danger",
            content: alertMock.errorServeur,
          });
        } else {
          setAlert({
            status: "success",
            content: data,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        setLoader(false);
        if ([404, 401, 500].includes(parseInt(error.response.status, 10))) {
          if (error.response.data.error === true) {
            setAlert({
              status: "danger",
              content: error?.response?.data.message,
            });
          }
        } else {
          setAlert({
            state: "danger",
            content: alertMock.errorServeur,
          });
        }
      });
  };

  React.useEffect(() => {
    if (select === "false") {
      setBaseImage([]);
    }
  }, [select]);

  return (
    <div className="row setup-content">
      <div className="row g-4 justify-content-start">
        <div className="col-lg-8 col-md-8">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              onChange={(event) => setSelect("true")}
              value={"true"}
              checked={select === "true"}
            />
            <label className="form-check-label" htmlFor="indicateurs">
              Si vous disposez des indicateurs financiers, veuillez nous fournir
              les indicateurs financiers suivants pour vos 3 dernières années.
            </label>
          </div>
          <br />
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              onChange={(event) => setSelect("false")}
              value={"false"}
              checked={select === "false"}
            />
            <label className="form-check-label" htmlFor="indicateurs">
              si vous ne disposez pas des indicateurs financiers, cliquez{" "}
              <a href="assets/file/indicateurs_financiers.xlsx">ici</a> pour
              télécharger le modele de fichier
            </label>
          </div>
        </div>

        {select === "true" && (
          <>
            <div className="container">
              <h2 className="display-6 mb-2">
                La liste des fichiers sélectionnés
              </h2>
              <ul className="list-group">
                {baseImage.map((item, key) => (
                  <li
                    className="list-group-item d-flex justify-content-between"
                    key={key}
                  >
                    {item.title}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(key)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg-lg-8 col-md-8">
              <div className="form-group">
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>
                    <p style={{ wordBreak: "break-all" }}>
                      Ajouter des fichiers
                      <br /> (Doit être
                      .doc,.docx,.xlxs,.text,.csv,.json,.xls,.jpg,.jpeg,.png,.svg)
                    </p>
                  </Form.Label>
                  <Form.Control
                    multiple
                    accept=".doc,.docx,.xlxs,.text.csv,.xls,.jpg,.jpeg,.png,.svg"
                    type="file"
                    onChange={uploadImg}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        )}

        {select === "false" && (
          <>
            <div className="container">
              <h2 className="display-6 mb-2">Le fichier sélectionné</h2>
              <ul className="list-group">
                {baseImage.map((item, key) => (
                  <li
                    className="list-group-item d-flex justify-content-between"
                    key={key}
                  >
                    {item.title}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(key)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="lg-lg-8 col-md-8">
              <div className="form-group">
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>
                    <p style={{ wordBreak: "break-all" }}>
                      Ajouter des fichiers
                      <br /> (Doit être .xlsx,.xls)
                    </p>
                  </Form.Label>
                  {baseImage.length >= 1 && (
                    <p className="text-danger">
                      Si vous souhaitez modifier le fichier, veuillez d'abord
                      supprimer le fichier sélectionné.
                    </p>
                  )}
                  <Form.Control
                    accept=".xlsx,.xls"
                    type="file"
                    onChange={uploadImg}
                    style={{ width: "100%", height: "100%" }}
                    disabled={baseImage.length >= 1}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row g-4 justify-content-start">
        <button
          style={{ width: "max-content" }}
          className="btn btn-danger nextBtn btn-lg pull-right me-2 col-2"
          type="button"
          onClick={() => setStepper(3)}
        >
          Précédent
        </button>

        {!loader && (
          <button
            style={{ width: "max-content" }}
            className="btn btn-success btn-lg pull-right col-2"
            type="button"
            disabled={baseImage.length <= 0}
            onClick={handleSubmit}
          >
            Soumettre
          </button>
        )}
        {loader && <ButtonLoader />}
      </div>
    </div>
  );
};

export default StepFour;
