import React from "react";
import RouteLinks from "../Router/Routes";

const Bacground = () => {
  let link = window.location.pathname;
  link = link.split("");
  link.shift();
  link = link.join("");
  link = link.split("-");
  link = link.join(" ");
  return (
    <div
      className="container-fluid page-header mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container text-center">
        <h1 className="display-4 text-white animated slideInDown mb-4" style={{textTransform:'uppercase'}}>
          {link}
        </h1>
        <nav aria-label="breadcrumb animated slideInDown">
          <ol className="breadcrumb justify-content-center mb-0">
            <li className="breadcrumb-item">
              <a className="text-white" href={RouteLinks.home}>
                Accueil
              </a>
            </li>
            <li
              className="breadcrumb-item text-primary active"
              aria-current="page"
              style={{textTransform:'capitalize'}}
            >
              {link}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Bacground;
