import React from "react";
import Bacground from "../Components/Bacground";

const Contact = () => {
  return (
    <>
      <Bacground />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                Contact
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="card-text">
                    <p className="mt-4">
                      Nous sommes là du lundi au vendredi de 9h à 17h.
                    </p>
                    <div className="flex row">
                      <svg
                        fill="none"
                        width="25"
                        className="col-1"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                       strokeWidth="1.5"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                         strokeWidth="1.5"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                         strokeWidth="1.5"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <div className="col-10">
                        Angré Djorobite Rond Point Cité SIR en bas Îlot 253 BIS
                        Villa Lot 2446, Abidjan-Côte d’Ivoire
                      </div>
                    </div>
                    <div className="flex row mt-4">
                      <svg
                        fill="none"
                        width="25"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                       strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        className="col-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                         strokeWidth="1.5"
                          d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                        />
                      </svg>
                      <div className="col-10">(+225) 07 08 06 48 48</div>
                    </div>
                    <div className="flex row mt-4">
                      <svg
                        fill="none"
                        width="25"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                       strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        className="col-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                         strokeWidth="1.5"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <div className="col-10">client@fasto.com</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              style={{ minHeight: "450px" }}
            >
              <div className="position-relative rounded overflow-hidden h-100">
                <iframe
                  className="position-relative w-100 h-100"
                  src="https://www.google.com/maps?q=5.393887042999268,-3.924990177154541&z=17&hl=en&output=embed"
                  frameBorder="0"
                  title="localisation"
                  style={{ minHeight: "450px", border: 0 }}
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
