import { RouterProvider } from "react-router-dom";
import Footer from "./Containers/Footer";
import Header from "./Containers/Header";
import Router from "./Router/Router";

function App() {
  return (
    <>
      <Header />
      <RouterProvider router={Router} />
      <Footer />
    </>
  );
}

export default App;
