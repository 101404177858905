import React from "react";
import Nav from "../Components/Nav";

const Header = () => {
  return (
    <div
      className="container-fluid fixed-top px-0 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
        <div className="col-lg-6 px-5 text-start">
          <small>
            <i className="fa fa-map-marker-alt me-2"></i>Angré Djorobite Rond
            Point Cité SIR
          </small>
          <small className="ms-4">
            <i className="fa fa-envelope me-2"></i>client@myfasto.com
          </small>
        </div>
      </div>
      <Nav />
    </div>
  );
};

export default Header;
