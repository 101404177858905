import React from "react";
import { Alert, Button } from "react-bootstrap";
import GetInCache from "../../Helpers/GetInCache";
import SessionService from "../../Services/SessionService";
import StepFour from "./StepFour";
import StepOne from "./StepOne";
import Stepper from "./Stepper";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const NewDemande = () => {
  const [stepper, setStepper] = React.useState(1);
  const [documentList, setDocumentList] = React.useState(null);
  const [alert, setAlert] = React.useState(null);
  const [accept, setAccept] = React.useState(false);
  const CacheValue = GetInCache();

  React.useEffect(() => {
    if (alert !== null) {
      const timeout = setTimeout(() => {
        setAlert(null);
      }, 20000);

      return () => timeout;
    }
  }, [alert]);

  React.useEffect(() => {
    if (accept) {
      SessionService.CreateAssitance(GetInCache()?.uuid)
        .then((values) => {
          setAccept(false);
        })
        .catch(console.log);
    }
  }, [accept]);
  return (
    <div className="row">
      {alert !== null && (
        <Alert
          variant={alert.status}
          style={{
            width: "90%",
            margin: "20vh auto",
            position: "fixed",
            top: "0",
            right: "0",
            transform: "translate(-50px,-50px)",
            zIndex: "99999999",
          }}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.content}
          {alert.status === "warning" && (
            <>
              <div className="d-flex justify-content-end gap-3">
                <Button variant="danger" onClick={() => setAlert(null)}>
                  Annuler
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setAccept(true);
                    setAlert(null);
                  }}
                >
                  Confirmer
                </Button>
              </div>
            </>
          )}
        </Alert>
      )}
      <Stepper stepper={stepper} />
      {CacheValue !== null && (
        <div
          style={{
            margin: "10px auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <Button variant="info">Renitialiser le formulaire</Button>
        </div>
      )}
      {stepper === 1 && (
        <StepOne
          stepper={stepper}
          setStepper={setStepper}
          setAlert={setAlert}
        />
      )}
      {stepper === 2 && (
        <StepTwo
          stepper={stepper}
          setStepper={setStepper}
          handleDocumentList={setDocumentList}
        />
      )}
      {stepper === 3 && (
        <StepThree
          stepper={stepper}
          setStepper={setStepper}
          documentInformation={documentList}
          setAlert={setAlert}
        />
      )}

      {stepper === 4 && (
        <StepFour setAlert={setAlert} setStepper={setStepper} />
      )}
    </div>
  );
};

export default NewDemande;
